// Depricated, use formation token variables now.

$blue-l5: #EAF4FF;
$blue-l3: #99CAFF;
$blue-l1: #2B90FF;
$blue: #0070EB;
$blue-d1: #005FC8;
$blue-svg: #1381E0;
$navy-l5: #EDEFF2;
$navy-l4: #C6D3E1;
$navy-l3: #9CB1CB;
$navy-l2: #819CBB;
$navy-l1: #6682A2;
$navy: #184C8B;
$navy-d1: #14417A;
$navy-d2: #103469;
$navy-d3: #05285A;
$navy-d4: #011638;
$navy-d5: #1F375B;
$grey-l5: #F7FBFF;
$grey-l4: #EAF0F6;
$grey-l3: #C9D1DB;
$grey-l2: #B0B7BF;
$grey-l1: #969DA3;
$red-d1: #DB3F3F;
$grey: #7F848A;
$grey-d1: #6A6F73;
$grey-d2: #2D2F30;
$grey-d3: #1D1E1F;
$grey-d4: #131314;
$grey-mid: #CFD6DB;
$black: #0A0A0A;
$white: #FFFFFF;
$gold: #C9A800;
$bronze: #8A7000;
$red-l4: #F9C9CE;
$yellow-l4: #FFDC2E;