@import '../../../styles/variables.scss';

@include for-mobile-down {
    .container {
        margin: auto;
        box-sizing: border-box;
        height: 71px;
        background: $white;
    }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 16px 0;
    gap: 24px;
    justify-content: space-between;
    height: 71px;

}

@include for-tablet-up {
    .container {
        display: none;
    }
}

.placeholder {
    width: 40px;
    max-width: 100%;
}