@import '../../../styles/variables.scss';

.spacer {
    height: 52px;
}

.container {
    box-sizing: border-box;
    position: fixed;
    top: 108px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px 16px 16px;
    gap: 16px;
    height: 52px;
    z-index: 50;
    background: $white;
    border-bottom: 1px solid $grey-l3;
    flex: none;
    order: 2;
    flex-grow: 0;

    button {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;

        span {
            flex-grow: 1;
            text-align: right;
        }
    }

    ul {
        background-color: $white;
        position: absolute;
        top: 52px;
        left: 0;
        right: 0;
        display: none;

        .link {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 12px 16px;
            background: $white;
            flex: none;
            order: 3;
            flex-grow: 0;
        }
    }
}

.container.active {
    ul {
        display: block;
    }
}

@include for-tablet-up {

    div.container {
        display: none;
    }

    .spacer {
        display: none;
    }
}