.footerSitemapLinks {
  max-width: 1280px;
  margin: auto;
}

.rgText {
  img {
    display: inline;
  }

}