@import '../../../styles/variables.scss';

.container {
    box-sizing: border-box;

    /* Auto layout */
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 1440px;
    height: 71px;
    max-width: 100%;
    margin: auto;
    background: $white;
}

@include for-mobile-down {
    div.container {
        display: none;
    }
}

@include for-tablet-up {
    div.container {
        display: flex;
    }
}