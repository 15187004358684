@import '../../../../styles/variables.scss';

.container {
    position: fixed;
    width: 100vw;
    top: 108px;
    background-color: $white;
    left: 0;
    line-height: 20px;
}

.menu {
    max-width: 95%;
    width: 1440px;
    margin: auto;

    padding: 0 16px;

    ul {

        height: 156px;
        width: 596px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &.more {
            height: 208px;
            width: 894px;
            max-width: 100%;
        }


        li {
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
                padding: 16px;
                border-radius: 16px;
                display: inline-block;
                width: 100%;
                line-height: 20px;

                span {
                    display: flex;
                    gap: 8px;
                }


                &:hover {

                    background: $blue-l5;

                }
            }
        }
    }
}

.title {
    font-family: 'Proxima Nova', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    margin: 34px 0 16px;
    border-bottom: 1px solid $grey-l3;
    display: inline-block;
    padding-bottom: 12px;
}