@import '../../styles/variables.scss';
@import '../../public/fonts/fonts.css';

.container {
  @include page-container;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, $navy-d3, #0b55c0);
  max-width: 1312px;
  height: 66px;
  border-radius: 8px;
  padding: 16px 32px;
  border: 1px solid #004ea3;
  margin-top: 24px;
  margin-bottom: 24px;
  color: white;

  .text {
    font-size: 24px;
    line-height: 30px;
    font-family: 'KnockoutCruiserweight', sans-serif;
    font-weight: 500;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    flex-shrink: 1;
    text-overflow: ellipsis;

    @include for-mobile-down {
      font-size: 14px;
      line-height: 18px;
    }

    @include for-extra-small-mobile-down {
      font-size: 13.5px;
      line-height: 16px;
    }
  }

  .winning {
    color: $yellow-l4;
  }
}

.left {
  display: flex;
  align-items: center;
  z-index: 1;
  flex-shrink: 1;
  min-width: 0;

  @include for-mobile-down {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 32px;
  z-index: 1;
  flex-shrink: 0;



  button {
    min-width: 120px;

    @include for-mobile-down {
      width: auto;
      min-width: 120px;
      white-space: nowrap;
    }
  }

  @include for-mobile-down {
    width: 100%;
    justify-content: center;
  }
}

.chevrons {
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .chevronsDesktop,
  .chevronsMobile {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
  }

  .chevronsDesktop {
    display: block;

    @include for-mobile-down {
      display: none;
    }
  }

  .chevronsMobile {
    display: none;

    @include for-mobile-down {
      display: block;
    }
  }
}

.playerImg {
  flex-shrink: 0;
  top: -50px;

  @include for-custom-max-width(1080px) {
    display: none;
  }
  @include for-mobile-down {
    display: none;
  }

  transform: translateY(10%);
}

@include for-mobile-down {
  .container {
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    gap: 8px;
    margin: 16px;
    padding: 16px;
    width: calc(100% - 32px);
  }

  .right {
    width: 100%;
    justify-content: center;
  }

  .playerImg {
    display: none;
  }

  .chevrons {
    position: absolute;
    right: 0;
  }
}

@include for-custom-max-width(959px) {
  .desktopLogo {
    display: none;
  }
}

@include for-custom-max-width(959px) {
  .desktopLogo {
    display: none;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  margin: 24px;

  @include for-mobile-down {
    height: 79.5px;
  }
}