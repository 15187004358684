@import '../../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    min-width: 53px;
    height: 72px;
    font-family: 'Proxima Nova', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: $grey-d4;

    img {
        display: inline;
        margin-right: 8px;
    }

}

.noLinkContainer {
    cursor: pointer;
}

.hoverContain {
    &:first-child {
        padding-left: 10px;
    }

    padding: 0 12px;

    &.hoverItem {

        .container {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-bottom: 2px solid $blue;
            font-weight: 700;
        }

        .submenu {

            display: block;

        }
    }
}

.submenu {
    display: none;

    position: relative;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 105px;
    height: 30px;

    img {
        margin-top: 40px;
    }
}

.selected {
    border-bottom: 2px solid $blue;
    font-weight: 700;
}