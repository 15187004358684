@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  margin-top: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
}

.subtext {
  margin-top: 0.5rem;
  line-height: 1.25rem;
  color: $fd-colors-core-grey-d-1;
}

.cta {
  margin-top: 1rem;
}

.button {
  display: flex;
  flex-wrap: nowrap;
  color: inherit;
}
