@import '../../../styles/variables.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 60;
  height: 108px;
  border-bottom: 1px solid $navy-l4;
  background: $white;
}

.scrollContainer {
  overflow-x: auto;
  background-color: $blue;

  @include scrollbar-hide;
}

.placeholder {
  height: 108px;
}

.researchNav {
  padding-left: 20px;
}