@import '../../../../styles/variables.scss';

.container {
    div[role="button"] {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 16px;
        width: 100%;
        height: 52px;
        flex: none;
        order: 2;
        flex-grow: 0;
    }
}

.submenu {
    li {
        display: none;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 40px;
        width: 100%;
        height: 44px;
        background: $grey-l4;
        flex: none;
        order: 10;
        flex-grow: 0;
    }
}


.active.submenu li {
    display: flex;
}

.arrowContainer {
    flex-grow: 1;
    text-align: right;
}