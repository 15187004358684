@import "~@fanduel/og-fe-library/dist/esm/index";
@import "./preflight.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.ReactModalPortal [class*="RedirectModal-module_overlay"] {
  z-index: 1000;
}

body {
  font-family: "Proxima Nova", sans-serif;
  color: $fd-colors-content-default;
  background-color: $fd-colors-background-base;
}
