/* Hides the scrollbar but maintains scrolling functionality */
@mixin scrollbar-hide {
  /* IE and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;

  /* Safari and Chrome */
  &::-webkit-scrollbar {
    display: none;
  }
}