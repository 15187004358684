// standardised breakpoints
$breakpoint-xl-desktop: 1440px;
$breakpoint-max-content: 1400px;
$breakpoint-lg-desktop: 1280px;
$breakpoint-desktop: 1024px;
$breakpoint-tablet: 960px;
$breakpoint-small-tablet: 812px;
$breakpoint-mobile: 640px;
$breakpoint-small-mobile: 360px;
$breakpoint-extra-small-mobile: 320px;

@mixin for-xl-desktop-up {
  @media (min-width: $breakpoint-xl-desktop) {
    @content;
  }
}

@mixin for-xl-desktop-down {
  @media (max-width: ($breakpoint-xl-desktop - 1)) {
    @content;
  }
}

@mixin for-max-content-up {
  @media (min-width: $breakpoint-max-content) {
    @content;
  }
}

@mixin for-max-content-down {
  @media (max-width: ($breakpoint-max-content - 1)) {
    @content;
  }
}

@mixin for-lg-desktop-up {
  @media (min-width: $breakpoint-lg-desktop) {
    @content;
  }
}

@mixin for-lg-desktop-down {
  @media (max-width: ($breakpoint-lg-desktop - 1)) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: ($breakpoint-lg-desktop - 1)) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin for-tablet-down {
  @media (max-width: ($breakpoint-desktop - 1)) {
    @content;
  }
}

@mixin for-tablet-up {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin for-mobile-down {
  @media (max-width: ($breakpoint-tablet - 1)) {
    @content;
  }
}

@mixin for-mobile-up {
  @media (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin for-small-mobile-down {
  @media (max-width: ($breakpoint-mobile - 1)) {
    @content;
  }
}

@mixin for-small-mobile-up {
  @media (min-width: $breakpoint-small-mobile) {
    @content;
  }
}

@mixin for-extra-small-mobile-down {
  @media (max-width: ($breakpoint-small-mobile - 1)) {
    @content;
  }
}

@mixin for-extra-small-mobile-up {
  @media (min-width: $breakpoint-extra-small-mobile) {
    @content;
  }
}

@mixin for-below-extra-small-mobile {
  @media (max-width: ($breakpoint-extra-small-mobile - 1)) {
    @content;
  }
}

@mixin for-custom-max-width($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin for-custom-min-width($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin for-small-tablet-down {
  @media (max-width: ($breakpoint-small-tablet)) {
    @content;
  }
}

@mixin for-small-tablet-up {
  @media (min-width: ($breakpoint-small-tablet + 1)) {
    @content;
  }
}

@mixin page-container {
  width: calc(100% - 128px);
  max-width: calc($breakpoint-xl-desktop - 128px);
  margin: 0 auto;

  @include for-xl-desktop-down {
    width: calc(100% - 48px);
  }

  @include for-tablet-down {
    width: calc(100% - 32px);
  }

  @include for-mobile-down {
    width: 100%;
  }
}