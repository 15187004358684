@import '../../styles/variables.scss';

.container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 0;

  @include for-tablet-up {
    padding: 8rem 0;
  }
}
