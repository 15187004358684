@import "../../../styles/variables.scss";

.container {
    display: none;
    background-color: $white;
    border-top: 1px solid $navy-l4;
    right: 0;
    left: 0;
    position: absolute;

    img {
        display: inline;
    }

    height: calc(100vh - 108px);
    overflow-y: auto;

    @include scrollbar-hide;

    ul {
        overflow-y: auto;

        @include scrollbar-hide;
    }
}

.container.menuIsOpen {
    display: block;
}